import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useQuery, useMutation } from "react-query";
import MaterialTable from "material-table";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import RefreshIcon from '@material-ui/icons/Refresh';
import ErrorPrivilegesPath from "../auth/error-privileges";
import 'date-fns';
import EditSection from './../C-catalog/catalog-edit-section';
import AddProduct from './../C-catalog/add-product';
import './../styles/globalClasses.css';
import { DialogContext, TranslationContext } from '../../App';
import {useHistory} from 'react-router-dom';

const Catalog = () => {
  const [redirect, setRedirect] = useState({state : false, status : 401})
  const {translations} = useContext(TranslationContext)
  const history = useHistory();
  const {setSuccessZero, apiPath, selectedModule, setSelectedModule, refreshState} = useContext(DialogContext)
  const [currentProduct, setCurrentProduct] = useState({}); // Datamajoris e pred-selektirana kompanija
  const [products, setProducts] = useState([]);
  const [serversDropDown, setServersDropDown] = useState([]);
  const [firstRender, setFirstRender] = useState(false)

  const response = useQuery("catalog", async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiPath}fetchProducts.php`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if(response.data.success){
        setFirstRender(true);
        response.data.total === "0" ? setProducts([]) : setProducts(response.data.products);
        if(typeof(currentProduct.pID) === "undefined")
          setCurrentProduct(response.data.products[0])
        else{
          response.data.products.forEach((product)=>{
              if(product.pID === currentProduct.pID )
              setCurrentProduct(product)
          })
        }
      }
      else {
        // TO DO
        if(response.data.status === 401 || response.data.status === 403) // unauthorized
          setRedirect({state : true, status : response.data.status}) 
        else
          setSuccessZero({state : true, name : translations?.locations?.errorFetchingProducts, text : translations?.backend[response.data.message]});
      }

      return response.data;
    } catch (err) {
    }
  });

  useQuery("servers-dropdown", async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiPath}fetchServers.php`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if(response.data.success){
        setFirstRender(true);
        let newServers = [];
        response.data.servers.forEach((server, index)=>{
          newServers.push({label : server.domain, value : server.id})
        })
        setServersDropDown(newServers);
      }
      else {
        // TO DO
        if(response.data.status === 401 || response.data.status === 403) // unauthorized
          setRedirect({state : true, status : response.data.status})
        else
          setSuccessZero({state : true, name : translations?.locations?.errorFetchingServers, text : translations?.backend[response.data.message]});
      }
  
      return response.data;
    } catch (err) {
    }
  })

  const mutation = useMutation((productData) => {
    const token = localStorage.getItem("token");

    switch(productData.tip){
      case "description":
        return axios.put(`${apiPath}updateProductDescription.php`, productData, { headers: { Authorization: `Bearer ${token}` } });
      case "settings":
        return axios.put(`${apiPath}updateProductSettings.php`, productData, { headers: { Authorization: `Bearer ${token}` } });
      case "servers":
        return axios.put(`${apiPath}updateProductServerID.php`, productData, { headers: { Authorization: `Bearer ${token}` } });
      case "add":
        return axios.post(`${apiPath}addProduct.php`, productData, { headers: { Authorization: `Bearer ${token}` } });  
      case "firmware":
        return axios.post(`${apiPath}updateProductFirmware.php`, productData, { headers: { Authorization: `Bearer ${token}` } }); 
      default: 
        break;
    }
  });

  const updateProduct = (productData) => {
    mutation.mutate(
      { ...productData },
      {
        onError: (error) => {
        },
        onSuccess: (dataReturned) => {
          if(dataReturned.data.success)
            response.refetch();
          else{
            switch(productData.tip){
              case "description":
                  setSuccessZero({state : true, name : translations?.products?.errorProductDescription, text : translations?.backend[dataReturned.data.message]});
                  break;
              case "settings":
                  setSuccessZero({state : true, name : translations?.products?.errorProductSettings, text : translations?.backend[dataReturned.data.message]});
                  break;
              case "servers":
                  setSuccessZero({state : true, name : translations?.products?.errorProductServers, text : translations?.backend[dataReturned.data.message]});
                  break;
              case "add":
                  setSuccessZero({state : true, name : translations?.products?.errorAddProduct, text : translations?.backend[dataReturned.data.message]});
                  break;
              case "firmware":
                  setSuccessZero({state : true, name : translations?.products?.errorOTAupdate, text : translations?.backend[dataReturned.data.message]});
                  break;
              default: 
                break;
            }
          }
        },
      }
    );
  };

  const addProduct = (values) => {
    updateProduct({tip : "add", ...values, productID : 4700, defaultSettings : {}, productGenericName : "DamaLuft"})
  }

  useEffect(() => {
    if(selectedModule !== "/catalog")
      setSelectedModule("/catalog")
  }, [])

  if(!firstRender && redirect.state === false)
    return <React.Fragment></React.Fragment>
  
  if(typeof(translations) === "undefined" || Object.keys(translations).length === 0)
    return <React.Fragment></React.Fragment>

  if(redirect.state){
    // ako ima token i sostojbata e ili neodredena ili tocna, ostavi go neka probat pak
    if((refreshState === '' || refreshState === 'true') && localStorage.getItem('token') !== null && redirect.status !== 403)
      history.go(0);
    else
      return <ErrorPrivilegesPath  status={redirect.status} />
  }

  return (
    <React.Fragment>
      <div className={"tableFS"}>
        <MaterialTable
          title={translations?.products?.tableCatalog}
          options={{
            padding: "dense",
            headerStyle : {padding : "3px"},
            actionsCellStyle : {paddingLeft : "12px"}, 
          }}
          localization={{
            header: { actions: "" },
            pagination : {
              firstTooltip : translations?.core?.firstPage,
              lastTooltip : translations?.core?.lastPage,
              nextTooltip : translations?.core?.nextPage,
              previousTooltip : translations?.core?.previousPage,
              labelRowsSelect: translations?.core?.rows,
              labelDisplayedRows: `{from}-{to} ${translations?.core?.rowsOf} {count}`
            },
            body : {
              emptyDataSourceMessage : translations?.core?.emptyTable
            },
            toolbar: {
              searchPlaceholder: translations?.core?.search,
              searchTooltip : translations?.core?.search
            },
          }}
          columns={[
            { title: translations?.products?.productType, field: "productGenericName",
                cellStyle : {padding : "0px 5px", paddingLeft : "45px"}, headerStyle : {paddingLeft : "45px"}},
            { title: translations?.products?.productName, field: "productName"},
            { title: translations?.products?.productCode, field: "productCode"},
            { title: translations?.products?.streamingAPI, field: "api"},
            { title: translations?.servers?.primaryServer, field: "domain1"},
            { title: translations?.servers?.backupServer, field: "domain2"},
            { title: translations?.products?.firmwareTitle, field: "fVersion"},
            { title: "serverID1", field: "serverID1", hidden : true},
            { title: "serverID2", field: "serverID2", hidden : true},
            { title: "pID", field: "pID", hidden : true},
          ]}
          data={products.map((dat) => {
            let code = dat.productID + dat.subID + dat.version;
            return {...dat, productCode : code}
          })}
          actions={[
            (rowData) => ({
              icon:
                currentProduct.pID === rowData.pID
                  ? CheckBoxIcon
                  : CheckBoxOutlineBlankIcon,
              tooltip: translations?.products?.selectProduct,
              onClick: (event, rowData) => {
                setCurrentProduct((rowData));
              },
            }),
            {
              icon: RefreshIcon,
              tooltip: translations?.core?.refresh,
              isFreeAction: true,
              onClick: (event) => response.refetch()
            }
          ]}
          onRowClick={(event, rowData) => {
            setCurrentProduct((rowData));
          }}
        />
          <AddProduct serversDropDown={serversDropDown} addProduct={addProduct}></AddProduct>
      </div>

      <EditSection serversDropDown={serversDropDown} 
                  current={currentProduct} updateProduct={updateProduct}></EditSection>
    </React.Fragment>
  );
};

export default Catalog;
