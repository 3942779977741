import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useQuery, useMutation } from "react-query";
import MaterialTable from "material-table";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import EditSection from './../C-test/test-edit-section'
import ErrorPrivilegesPath from "../auth/error-privileges";
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';
import BlockIcon from '@material-ui/icons/Block';
import LoopIcon from '@material-ui/icons/Loop';
import RefreshIcon from '@material-ui/icons/Refresh';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import './../styles/globalClasses.css'
import { DialogContext, TranslationContext} from './../../App';
import {GraphDM} from './../C-inventar/graphDM.js'
import {useHistory} from 'react-router-dom';
import {formatDatumVreme} from './../formatDates'

const Test = () => {
  const [redirect, setRedirect] = useState({state : false, status : 401})
  const {translations} = useContext(TranslationContext)
  const {setSuccessZero, apiPath, setNotifyPopUp, selectedModule, setSelectedModule, refreshState} = useContext(DialogContext)
  const history = useHistory();
  const [currentDevice, setCurrentDevice] = useState({}); // Datamajoris e pred-selektirana kompanija
  const [inventoryTest, setInventoryTest] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [notShowGraph, setNotShowGraph] = useState(true);
  const [firstRender, setFirstRender] = useState(false)

  const response = useQuery("inventory-test", async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiPath}fetchInventoryTest.php`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if(response.data.success){
        setFirstRender(true);
        setInventoryTest(response.data.devices);
        if(typeof(currentDevice.serialID) === "undefined")
          setCurrentDevice(response.data.devices[0])
        else{
          response.data.devices.forEach((device)=>{
              if(device.serialID === currentDevice.serialID )
              setCurrentDevice(device)
          })
        }
      }
      else {
        // TO DO
        if(response.data.status === 401 || response.data.status === 403) // unauthorized
          setRedirect({state : true, status : response.data.status})
        else
          setSuccessZero({state : true, name : translations?.locations?.errorFetchingDevicesTest, text : translations?.backend[response.data.message]});
      }

      return response.data;
    } catch (err) {
    }
  });

  useQuery("product-types-dropdown", async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiPath}fetchProducts.php`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if(response.data.success){
        setFirstRender(true);
        let newProductTypes = [];
        response.data.products.forEach((product)=>{
            newProductTypes.push({productName : product.productName, pID : product.pID})
        })
        setProductTypes(newProductTypes);
      }
      else {
        // TO DO
        if(response.data.status === 401 || response.data.status === 403) // unauthorized
          setRedirect({state : true, status : response.data.status})
        else
          setSuccessZero({state : true, name : translations?.locations?.errorFetchingProducts, text : translations?.backend[response.data.message]});
      }

      return response.data;
    } catch (err) {
    }
  });

  const mutation = useMutation((deviceData) => {
    const token = localStorage.getItem("token");
    
    switch(deviceData.tip){
      case "tested":
        return axios.put(`${apiPath}updateDeviceTested.php`, deviceData, { headers: { Authorization: `Bearer ${token}` } });
      case "intest":
        return axios.put(`${apiPath}updateDeviceTestState.php`, deviceData, { headers: { Authorization: `Bearer ${token}` } });
      case "comment":
        return axios.put(`${apiPath}updateDeviceComment.php`, deviceData, { headers: { Authorization: `Bearer ${token}` } });
      case "type":
        return axios.put(`${apiPath}updateDeviceType.php`, deviceData, { headers: { Authorization: `Bearer ${token}` } });
      case "settings":
        return axios.put(`${apiPath}updateDeviceSettings.php`, deviceData, { headers: { Authorization: `Bearer ${token}` } });
      case "deleteData":
        return axios.post(`${apiPath}deleteDeviceData.php`, deviceData, { headers: { Authorization: `Bearer ${token}` } });  
      default: 
        break;
    }
  });

  const updateTestDevice = (deviceData) => {
    mutation.mutate(
      { ...deviceData },
      {
        onError: (error) => {
        },
        onSuccess: (dataReturned) => {
          if(dataReturned.data.success){
            response.refetch();
            if (deviceData.tip === "tested")
              setNotifyPopUp({state : true, name : translations?.devices?.successfullyTestedPopUpNaslov, text : translations?.devices?.successfullyTestedPopUpMessage})
          }
          else{
            switch(deviceData.tip){
              case "tested":
                  setSuccessZero({state : true, name : translations?.devices?.errorTested, text : translations?.backend[dataReturned.data.message]});
                  break;
              case "intest":
                  setSuccessZero({state : true, name : translations?.devices?.errorIntest, text : translations?.backend[dataReturned.data.message]});
                  break;
              case "comment":
                  setSuccessZero({state : true, name : translations?.devices?.errorComment, text : translations?.backend[dataReturned.data.message]});
                  break;
              case "type":
                  setSuccessZero({state : true, name : translations?.devices?.errorProductType, text : translations?.backend[dataReturned.data.message]});
                  break;
              case "settings":
                setSuccessZero({state : true, name : translations?.devices?.errorSettings, text : translations?.backend[dataReturned.data.message]});
                break;
              case "deleteData":
                setSuccessZero({state : true, name : translations?.data?.errorDeleteData, text : translations?.backend[dataReturned.data.message]});
                break;
              default: 
                break;
            }
          }
        },
      }
    );
  };

  useEffect(() => {
    if(selectedModule !== "/test")
      setSelectedModule("/test")
  }, [])

  if(!firstRender && redirect.state === false)
    return <React.Fragment></React.Fragment>

  if(typeof(translations) === "undefined" || Object.keys(translations).length === 0)
    return <React.Fragment></React.Fragment>
  
  if(redirect.state){
    // ako ima token i sostojbata e ili neodredena ili tocna, ostavi go neka probat pak
    if((refreshState === '' || refreshState === 'true') && localStorage.getItem('token') !== null && redirect.status !== 403)
      history.go(0);
    else
      return <ErrorPrivilegesPath  status={redirect.status} />
  }

  return (
    <React.Fragment>
      <div className={"tableFS"}>
        <MaterialTable
          title={translations?.devices?.tableTest}
          options={{
            padding: "dense",
            headerStyle : {padding : "3px"},
          }}
          localization={{
            header: { actions: translations?.core?.actions },
            pagination : {
              firstTooltip : translations?.core?.firstPage,
              lastTooltip : translations?.core?.lastPage,
              nextTooltip : translations?.core?.nextPage,
              previousTooltip : translations?.core?.previousPage,
              labelRowsSelect: translations?.core?.rows,
              labelDisplayedRows: `{from}-{to} ${translations?.core?.rowsOf} {count}`
            },
            body : {
              emptyDataSourceMessage : translations?.core?.emptyTable
            },
            toolbar: {
              searchPlaceholder: translations?.core?.search,
              searchTooltip : translations?.core?.search
            },
          }} 
          columns={[
            { title: translations?.products?.productType, field: "productName",
                cellStyle : {padding : "0px 5px", paddingLeft : "45px"}, headerStyle : {paddingLeft : "45px"}},
            { title: translations?.devices?.serial, field: "serialNumber"},
            { title: "pID", field: "pID", hidden : true},
            { title: "inuse", field: "inuse", hidden : true},
            { title: "intest", field: "intest", hidden : true},
            { title: "tested", field: "tested", hidden : true},
            { title: translations?.data?.latestReading, field: "lastUpdate"},
            { title: translations?.devices?.comment, field: "comment"},
          ]}
          data={inventoryTest.map((dat) => {
            let com;
            dat.comment.length > 70 ? com = dat.comment.slice(0, 67) + "..." : com = dat.comment;
            dat.inuse = parseInt(dat.inuse)
            dat.intest = parseInt(dat.intest)
            dat.tested = parseInt(dat.tested)
            return {...dat, comment : com, lastUpdate : formatDatumVreme(dat.lastUpdate, translations?.data?.day)}
          })}
          actions={[
            (rowData) => ({
              icon:
                currentDevice.serialID === rowData.serialID
                  ? CheckBoxIcon
                  : CheckBoxOutlineBlankIcon,
              tooltip: translations?.devices?.selectDevice,
              onClick: (event, rowData) => {
                inventoryTest.forEach((device) => {
                  if(device.serialID === rowData.serialID)
                    setCurrentDevice((device));
                })
              },
            }),
            (rowData) => ({
              icon: DeleteSweepIcon,
              tooltip: translations?.data?.deleteData,
              onClick: (event, rowData) => {
                updateTestDevice({tip : "deleteData", serialID : rowData.serialID})
              },
            }),
            (rowData) => ({
                icon: rowData.tested ? DoneAllOutlinedIcon : BlockIcon,
                tooltip: rowData.tested ? translations?.devices?.tested : translations?.devices?.markAsTested,
                onClick: (event, rowData) => {
                    updateTestDevice({tip : "tested", serialID : rowData.serialID, tested : true})
                },
              }),
            (rowData) => ({
                icon: rowData.intest ? LoopIcon : BlockIcon,
                tooltip: rowData.intest ? translations?.devices?.stopTesting : translations?.devices?.startTesting,
                onClick: (event, rowData) => {
                    updateTestDevice({tip : "intest", serialID : rowData.serialID, intest : true})
                },
            }),
            (rowData) => ({
              icon: ShowChartIcon,
              tooltip: translations?.data?.showChart,
              onClick: (event, rowData) => {
                if(rowData.serialID === currentDevice.serialID)
                  setNotShowGraph(!notShowGraph)
                else
                  setNotShowGraph(false)

                inventoryTest.forEach((device) => {
                  if(device.serialID === rowData.serialID)
                    setCurrentDevice((device));
                })
              },
            }),
            {
              icon: RefreshIcon,
              tooltip: translations?.core?.refresh,
              isFreeAction: true,
              onClick: (event) => response.refetch()
            }
          ]}
          onRowClick={(event, rowData) => {
            inventoryTest.forEach((device) => {
              if(device.serialID === rowData.serialID)
                setCurrentDevice((device));
            })
          }}
        />
      </div>
      {!notShowGraph && (
          <GraphDM  serialID={currentDevice.serialID} setNotShowGraph={setNotShowGraph} />
      )}
      <EditSection current={currentDevice} updateTestDevice={updateTestDevice} typesDropDown={productTypes}></EditSection>
    </React.Fragment>
  );
};

export default Test;
