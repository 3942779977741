import React, { useEffect, useState, useContext, useMemo, useRef } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import { DialogContext, TranslationContext } from '../../App';
import { TabelaAnaliza } from '../C-analiza/tabela-analiza';
import {TextField, InputAdornment} from "@material-ui/core";
import Slider from '@material-ui/core/Slider';
import './../styles/globalClasses.css'
import './../C-users/checkbox.css'
import ErrorPrivilegesPath from '../auth/error-privileges';
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import {useHistory} from 'react-router-dom';

const DOUBLE_VIEW_PIKSELI = 1000;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: '0.5em',
    width : "150px"
  },
  formSelectBuilding: {
    minWidth: '80px'
  },
  formSelectPlace: {
    minWidth: '70px'
  },
  formSelectDevice: {
    minWidth: '70px'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  secondSegment : {
    marginTop : "50px"
  },
  analysisButton : {
    marginLeft : "5px",
    marginRight : "5px",
    borderRadius : "5px",
    cursor : "pointer",
    color : "white",
    width : "120px",
    height : "40px",
    "&:disabled" : {
      opacity : "0.85",
      cursor : "wait"
    },
    [theme.breakpoints.down(DOUBLE_VIEW_PIKSELI)]: {
      marginBottom : "50px"
    },
  },
  settings : {
    borderRight : "2px dashed black",
    paddingRight : "40px",
    paddingLeft : "20px",
    borderRadius : "10px",
    boxSizing : "border-box",
    float : "left",
    width : "320px",
    [theme.breakpoints.down(DOUBLE_VIEW_PIKSELI)]: {
      border : "none",
      borderRadius : "none",
      float : "none",
      display : "block",
      margin : "auto",
      width : "calc(100% - 20px)",
    },
  },
  pragovi : {
    width : "200px",
    margin : "auto",
    marginBottom : "10px",
  },
  slider : {
    width : "250px",
    margin : "auto",
    marginTop : "30px",
  },
  tableAnaliza : {
    float : "left",
    marginLeft : "20px",
    width : "calc(100% - 360px)",
    marginBottom : "50px",
    [theme.breakpoints.down(DOUBLE_VIEW_PIKSELI)]: {
      float : "none",
      display : "block",
      marginLeft : "10px",
      marginRight : "10px",
      width : "calc(100% - 20px)",
    },
    [theme.breakpoints.down(550)]: {
      margin : "0",
      padding : "0",
      width : "100%",
      minWidth : "300px"
    },
  },
  interval : {
    diplay : "inline-block",
    textAlign : "center",
    marginTop : "20px",
    fontWeight : "bold",
    marginBottom : "15px",
  },
  dropdownDiv : {
    display : "flex",
    justifyContent : "center",
  },
  orangeLabel : {
    color : "orange"
  },
  weekends : {
    textAlign : "center",
    marginTop : "10px",
    marginBottom : "20px",
    fontWeight : "normal"
  }
}));

const validateHHMM = (time)  => {
  return /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(time) || time === "24:00";
}

export const Analiza = () => {
    const classes = useStyles();

    const [redirect, setRedirect] = useState({state : false, status : 401})
    const {translations} = useContext(TranslationContext)
    const history = useHistory();
    const {setSuccessZero, apiPath, selectedModule, setSelectedModule, refreshState} = useContext(DialogContext)
    const [firstRender, setFirstRender] = useState(false)

    const [ pragCrven, setPragCrven ] = useState(1200);
    const [ pragZolt, setPragZolt ] = useState(800);
    const [activeHours, setActiveHours] = useState({startTime : "07:30", endTime : "17:30"})
    const [interval, setInterval] = useState(1)
    const [weekends, setWeekends] = useState(false);

    const [locData, setLocData] = useState({})
    const [analysis, setAnalysis] = useState({state : false, single : false, multiple : false, refetch : false})

    const [ buildings, setBuildings ] = useState([]);
    const [ places, setPlaces ] = useState([]);
    const [ devices, setDevices ] = useState([]);

    const [ building, setBuilding ] = useState({});
    const [ place, setPlace ] = useState({});
    const [ device, setDevice ] = useState({});
    
    const [width, setWidth]   = useState(window.innerWidth);

    const [buttonDisabled, setButtonDisabled] = useState(false)

    const refActiveHours = useRef(null)

    useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      window.addEventListener("orientationchange", updateOrientation)
      return () => {
          window.removeEventListener("resize", updateDimensions)
          window.removeEventListener("orientationchange", updateOrientation)
      };
    }, []);

    const updateDimensions = () => {setWidth(window.innerWidth)};
    const updateOrientation = () => {
        setTimeout(() => {setWidth(window.innerWidth)}, 10) 
        setTimeout(() => {setWidth(window.innerWidth)}, 50) 
        setTimeout(() => {setWidth(window.innerWidth)}, 100) 
    }
  
    // zema user access, posto do ovaa strana imaat pristap samo admini userAccess ne mora da se filtrira
    // se gleda dali vo localStorage ima prethodno zacuvani parametri za interval, pragZolt, pragCrven, zgrada, mesto, ured i se pred-selektiraat istite
    // dokolku nema pred-selektirani zgrada, mesto i ured, togas se selektiraat prvata zgrada, prvoto mesto i prviot ured (dokolku postoi)
    useQuery("userAccess-analiza", async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(`${apiPath}fetchUserAccess.php`, { headers: { Authorization: `Bearer ${token}` } });

            if(response.data.success){
              setFirstRender(true);
              response.data?.allowed.forEach((singleProduct) => {
                if(singleProduct.productGenericName === "DamaLuft"){
                  setLocData(singleProduct);
                  const defaultsA = JSON.parse(localStorage.getItem("defaultsA")) || {};
                  
                  // od localStorage parametri za analiza
                  if(defaultsA.activeHours?.startTime !== undefined && validateHHMM(defaultsA.activeHours?.startTime) && 
                      defaultsA.activeHours?.endTime !== undefined && validateHHMM(defaultsA.activeHours?.endTime)){
                      setActiveHours({startTime : defaultsA.activeHours.startTime, endTime : defaultsA.activeHours.endTime})
                  }
                  if(defaultsA.pragPrv !== undefined && !isNaN(defaultsA.pragPrv))
                      setPragZolt(defaultsA.pragPrv)
                  if(defaultsA.pragVtor !== undefined && !isNaN(defaultsA.pragVtor))
                      setPragCrven(defaultsA.pragVtor)
                  if(defaultsA.interval !== undefined && [1, 3, 7, 30].indexOf(defaultsA.interval) !== -1)
                      setInterval(defaultsA.interval)
                  if(defaultsA.weekends !== undefined)
                      setWeekends(defaultsA.weekends) // ensure boolean value

                  let newZgradi = [];
                  let newMesta = [];
                  let newUredi = [];

                  let newZgrada = {};
                  let newMesto = {};
                  let newUred = {};

                  // Resava problemi ako nekoj cepka vo localStorage i izbrisit primer samo "mesto" od objektot defaultsA
                  let celosnoZacuvan = false

                  singleProduct.dataP.forEach((singleZgrada, indexZgrada) => {
                    newZgradi.push({zgradaID : singleZgrada.zgradaID, zgradaName : singleZgrada.zgradaName})
                    if(typeof(defaultsA) !== "undefined"){
                      if(defaultsA.building !== undefined){ // ima zacuvana zgrada
                        if(singleZgrada.zgradaName === defaultsA.building){
                            newZgrada = newZgradi[newZgradi.length - 1]
                            singleZgrada.dataZ.forEach((singleMesto) => {
                              newMesta.push({mestoID : singleMesto.mestoID, mestoName : singleMesto.mestoName})
                              if(defaultsA.place !== undefined){ // ima zacuvano mesto
                                if(singleMesto.mestoName === defaultsA.place){
                                  newMesto = newMesta[newMesta.length - 1]
                                  singleMesto.dataM.forEach((singleUred) => {
                                      newUredi.push({deviceID : singleUred.deviceID, deviceName : singleUred.deviceName})
                                      if(defaultsA.device !== undefined){  // ima zacuvan ured
                                        if(singleUred.deviceName === defaultsA.device){
                                          newUred = newUredi[newUredi.length - 1]
                                          celosnoZacuvan = true
                                          // setAnalysis se postavuva ponataka za da se izbegnit error
                                        }
                                      }
                                    })
                                  }
                              }
                            })
                          }
                      }
                    }
                  })

                  setBuildings(newZgradi);
                  setPlaces(newMesta);
                  setDevices(newUredi);

                  // ako imat zacuvan ured
                  if(celosnoZacuvan){
                    setDevice(newUred)
                    setPlace(newMesto)
                    setBuilding(newZgrada)
                    setAnalysis({state : true, single : true, multiple : false, refetch : true}) // ako ima vekje zacuvan ured, odma prikazi go
                  }
                  // ako nemat zacuvan ured
                  else{
                    setDevice({deviceID : "", deviceName : ""})
                    setPlace({mestoName : "", mestoID : ""})
                    setBuilding({zgradaName : "", zgradaID : ""})
                  }
                }
            })
            // za kompanii bez svoi uredi
            if(response.data.allowed.length === 0){
              setDevice({deviceID : "", deviceName : ""})
              setPlace({mestoName : "", mestoID : ""})
              setBuilding({zgradaName : "", zgradaID : ""})
            }
            
          }
          // redirect na unauthorized / forbidden, error Dialog na drug error
          else {
            // TO DO
            if(response.data.status === 401 || response.data.status === 403) // unauthorized
              setRedirect({state : true, status : response.data.status})
            else
              setSuccessZero({state : true, name : translations?.access?.errorFetchingAccess
                              , text : translations?.backend[response.data.message]});
          }

            return response.data;
        } catch (err) {
        }
      });

    // koga se odbira zgrada, za mesto i ured selektiraj gi prvoto mesto i prviot ured
    function handleBuldingSelect(e) {
        let newPlaces = []
        
        locData.dataP.forEach((singleZgrada) => {
            if(singleZgrada.zgradaID === e.target.value){
                setBuilding({zgradaID : singleZgrada.zgradaID, zgradaName : singleZgrada.zgradaName})
                singleZgrada.dataZ.forEach((singleMesto, indexMesto) => {
                    newPlaces.push({mestoID : singleMesto.mestoID, mestoName : singleMesto.mestoName})
                })
            }
        })

        setPlaces(newPlaces);

        setDevice({deviceID : "", deviceName : ""})
        setPlace({mestoName : "", mestoID : ""})
    }

    // koga se odbira mesto, za ured selektiraj go prviot ured
    function handlePlaceSelect(e) {
        let newUredi = [];
        
        locData.dataP.forEach((singleZgrada) => {
            if(singleZgrada.zgradaID === building.zgradaID){
                singleZgrada.dataZ.forEach((singleMesto) => {
                    if(singleMesto.mestoID === e.target.value){
                        setPlace({mestoID : singleMesto.mestoID, mestoName : singleMesto.mestoName})
                        singleMesto.dataM.forEach((singleUred, indexUred) => {
                            newUredi.push({deviceID : singleUred.deviceID, deviceName : singleUred.deviceName})
                        })
                    }
                })
            }
        })

        setDevices(newUredi)
        setDevice({deviceID : "", deviceName : ""})
    }
    
    // selektiraj ured
    function handleDeviceSelect(e) {
        devices.forEach((singleUred) => {
            if(singleUred.deviceID === e.target.value){
                setDevice({deviceID : singleUred.deviceID, deviceName : singleUred.deviceName})

            }
        })
    }

    // zacuvaj relevantni parametri pri pobaruvanje na edinencna analiza
    const handleSingleAnalysis = () => {
      setButtonDisabled(true)

      let defaultsA = JSON.parse(localStorage.getItem("defaultsA")) || {}; 
      defaultsA["building"] = building.zgradaName;
      defaultsA["place"] = place.mestoName;
      defaultsA["device"] = device.deviceName;
      defaultsA["pragPrv"] = Number(pragZolt)
      defaultsA["pragVtor"] = Number(pragCrven);
      defaultsA["interval"] = interval;
      defaultsA["weekends"] = weekends;
      let nizaAH = refActiveHours.current.innerHTML.split("<br>")[1].split(" - ")
      let strStartTime = nizaAH[0]
      let strEndTime = nizaAH[1]
      defaultsA["activeHours"] = {startTime : strStartTime, endTime : strEndTime};

      localStorage.setItem("defaultsA", JSON.stringify(defaultsA));

      setAnalysis({state : true, single : true, multiple : false, refetch : true})
    }

    // zacuvaj relevantni parametri pri pobaruvanje na povekjekratna analiza
    const handleMultipleAnalysis = () => {
      setButtonDisabled(true)

      let defaultsA = JSON.parse(localStorage.getItem("defaultsA")) || {}; 

      defaultsA["pragPrv"] = Number(pragZolt)
      defaultsA["pragVtor"] = Number(pragCrven);
      defaultsA["interval"] = interval;
      defaultsA["weekends"] = weekends;
      let nizaAH = refActiveHours.current.innerHTML.split("<br>")[1].split(" - ")
      let strStartTime = nizaAH[0]
      let strEndTime = nizaAH[1]
      defaultsA["activeHours"] = {startTime : strStartTime, endTime : strEndTime};

      localStorage.setItem("defaultsA", JSON.stringify(defaultsA));

      setAnalysis({state : true, single : false, multiple : true, refetch : true})
    }

    // koristi useMemo za prikaz na podatoci od analizata (Tabela - multiple ili box - single)
    // za da se izbegne rerender koga se lizga slajderot od RangeSlider komponentata (koj sto menuva sostojba na glavnata komponenta)
    const displayTabelaAnaliza = useMemo(() => {
      return (
        <React.Fragment>
          {analysis.state && 
            <TabelaAnaliza
              prvPrag={pragZolt}
              vtorPrag={pragCrven}
              interval={interval}
              startTime={activeHours.startTime}
              endTime={activeHours.endTime}
              serialID={device?.deviceID}
              deviceName={device?.deviceName}
              analysis={analysis}
              weekends={weekends}
              setAnalysis={setAnalysis}
              productID={4700}
              setButtonDisabled={setButtonDisabled}
            />
          }
        </React.Fragment>
        )},  [analysis.refetch, translations]);

    // koristi useMemo za render na dropdown menija i kopcija za analiza 
    // za da se izbegne rerender koga se lizga slajderot od RangeSlider komponentata (koj sto menuva sostojba na glavnata komponenta)
    const displayLocationSelectors = useMemo(() => {
      return (
        <div style={{marginTop : "10px"}}>
          <div className={classes.dropdownDiv}>
            <FormControl className={classes.formControl}>
              <InputLabel id="zgrada">{translations?.locations?.building}</InputLabel>
              <Select
                labelId="zgrada"
                id="zgrada"
                onChange={handleBuldingSelect}
                autoWidth={true}
                className={classes.formSelectBuilding}
                value={building?.zgradaID}
              >
                {buildings.map((building) => (
                  <MenuItem key={building.zgradaID} value={building.zgradaID}>
                    {building.zgradaName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel id="mesto">{translations?.locations?.place}</InputLabel>
              <Select
                labelId="mesto"
                id="mesto"
                autoWidth={true}
                className={classes.formSelectPlace}
                onChange={handlePlaceSelect}
                value={place?.mestoID}
              >
                {places.map((place) => (
                  // FIX: key may not be unique
                  <MenuItem key={place.mestoID} value={place.mestoID}>
                    {place.mestoName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel id="mesto">{translations?.devices?.device}</InputLabel>
              <Select
                labelId="product"
                id="product"
                autoWidth={true}
                className={classes.formSelectDevice}
                onChange={handleDeviceSelect}
                value={device?.deviceID}
              >
                {devices.map((device_) => (
                  // FIX: key may not be unique
                  <MenuItem key={device_.deviceID} value={device_.deviceID}>
                    {device_.deviceName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      )
    }, [buildings, places, devices, building, place, device, translations, analysis, pragZolt, pragCrven, interval, width, buttonDisabled])

    useEffect(() => {
      if(selectedModule !== "/analiza")
        setSelectedModule("/analiza")
    }, [])

    if(!firstRender && redirect.state === false)
      return <React.Fragment></React.Fragment>

    // ce se trgni ovaj 'if' koga ce postavime translations?.users?.user || "User" stil na prevodi (ili mozebi da ne go trgame ?!)
    if(typeof(translations) === "undefined" || Object.keys(translations).length === 0)
      return <React.Fragment></React.Fragment>

    if(redirect.state){
      // ako ima token i sostojbata e ili neodredena ili tocna, ostavi go neka probat pak
      if((refreshState === '' || refreshState === 'true') && localStorage.getItem('token') !== null && redirect.status !== 403)
        history.go(0);
      else
        return <ErrorPrivilegesPath  status={redirect.status} />
    }
      
    // da ne menuvame controlled - uncontrolled input dodeka building, place i device se undefined
    if (building?.zgradaID === undefined || place?.mestoID === undefined || device?.deviceID === undefined)
      return <React.Fragment></React.Fragment>

    return (
      <>
        <div>
            {displayLocationSelectors}
        </div>
        <div className={classes.secondSegment}>
          <div className={classes.settings}>
            <h2 className="naslovMal">{translations?.devices?.settings}</h2>
            <div className={classes.pragovi}>
              <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EditOutlinedIcon />
                      </InputAdornment>
                    )
                  }}
                  inputProps={{style: { textAlign: 'right' }}}
                  InputLabelProps={{
                    style : {color : "#f19100", fontWeight : "bold"}
                  }}
                  fullWidth={true}
                  label={`${translations?.data?.limit1} [ppm]`} 
                  placeholder={"800"}
                  onChange={(event) => {setPragZolt(event.target.value)}}
                  value={pragZolt}
              />
            </div>
            <div className={classes.pragovi}>
              <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EditOutlinedIcon />
                      </InputAdornment>
                    )
                  }}
                  inputProps={{style: { textAlign: 'right' }}}
                  InputLabelProps={{
                    style : {color : "red", fontWeight : "bold"}
                  }}
                  label={`${translations?.data?.limit2} [ppm]`}
                  placeholder={"1200"}
                  onChange={(event) => {setPragCrven(event.target.value)}}
                  value={pragCrven}
              />
            </div>
            {/* slajder komponenta, ja ima podolu */}
            <RangeSlider 
              startTime={Number(activeHours.startTime.split(':')[0] * 60) + Number(activeHours.startTime.split(':')[1])}
              endTime={Number(activeHours.endTime.split(':')[0] * 60) + Number(activeHours.endTime.split(':')[1])}
              setActiveHours={setActiveHours}
              refActiveHours={refActiveHours}
            />
            <div className={classes.interval}>{translations?.data?.interval}:
              <Select
                autoWidth={true}
                className={classes.formSelectDevice}
                style={{marginLeft : "30px"}}
                onChange={(event) => setInterval(event.target.value)}
                value={interval}
              >
                {[1, 3, 7, 30].map((singleInterval) => (
                  // FIX: key may not be unique
                  <MenuItem key={singleInterval} value={singleInterval}>
                    {singleInterval !== 1 && singleInterval} {singleInterval === 1 ? translations?.data?.day : translations?.data?.days}
                  </MenuItem>
                ))}
              </Select>
            <div className={classes.weekends}>
              <input type="checkbox" value={weekends} id="weekends" name="weekends" onClick={() => {setWeekends(!weekends)}} style={{transform : "scale(1.2)"}} />
              <label htmlFor="weekends" style={{paddingLeft : "5px"}} >{translations?.data?.weekends}</label>
            </div>
            <div className={classes.dropdownDiv}>
              <button onClick={handleMultipleAnalysis} className={classes.analysisButton} disabled={buttonDisabled} style={{backgroundColor : "var(--blue)"}}>
                {translations?.data?.getMultipleAnalysis}
              </button>

              <button onClick={handleSingleAnalysis} className={classes.analysisButton} disabled={buttonDisabled} style={{backgroundColor : "var(--blueLighter)"}}>
                {translations?.data?.getSingleAnalysis}
              </button>
            </div>
          </div>
        </div>
          <div className={classes.tableAnaliza}>
            {displayTabelaAnaliza}
          </div>
        </div>
      </>
    )
}

// za prikaz vo dobar format
const formatValue = (value) => {
  let hours = parseInt(value / 60)
  let minutes = parseInt(value % 60)
  if(hours < 10)
    hours = '0' + hours
  if(minutes < 10)
    minutes = '0' + minutes

  return `${hours}:${minutes}`;
}

const RangeSlider = (props) => {
  const classes = useStyles();
  const {translations} = useContext(TranslationContext)
  const [value, setValue] = useState([props.startTime, props.endTime]);

  const handleChange = (event, newValue) => {
    if(Math.abs(newValue[0] - newValue[1]) >= 120){
      setValue(newValue);
      props.setActiveHours({startTime : formatValue(Math.min(...newValue)), endTime : formatValue(Math.max(...newValue))})
    }
  };

  return (
    <div className={classes.slider}>
      <Slider
        value={value}
        min={0}
        max={1440}
        step={10}
        onChange={handleChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => <div>{formatValue(value)}</div>}
      />
      <div style={{fontWeight : "bold", textAlign : "center"}} ref={props.refActiveHours}>
        {translations?.data?.activeHours}<br /> 
        {formatValue(Math.min(...value))} - {formatValue(Math.max(...value))}</div>
    </div>
  );
}

