import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { TextField } from "@material-ui/core";
import { TranslationContext, DialogContext } from "../../App";
import Select from "react-select";
import {SaveButton} from './../edit-elements'

export const FirmwareSelection = (props) => {
    const {translations} = useContext(TranslationContext)
    const {apiPath} = useContext(DialogContext)
    const [currentFirmware, setCurrentFirmware] = useState({fID : 0, fileName : "", version : ""})
    const [firmwares, setFirmwares] = useState([])

    useEffect(() => {
        response.refetch()
    }, [props])

    const response = useQuery("firmwareVersions", async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(`${apiPath}fetchFirmwareVersions.php?productName=${props.productName}`, { headers: { Authorization: `Bearer ${token}` } });

            if(response.data.success){
                let newFirmwares = []
                response.data.firmwares.forEach((firmware) => {
                    newFirmwares.push({
                        label : firmware.fileName,
                        value : firmware.fID,
                        fID : firmware.fID,
                        version : firmware.version,
                        fileName : firmware.fileName
                    })
                })

                if(newFirmwares.length){
                    setFirmwares(newFirmwares)
                    newFirmwares.forEach((firmware) => {
                        if(firmware.version === props.fVersion)
                            setCurrentFirmware(firmware)
                    })
                }
            }

            return response.data;
        } catch (err) {
        }
    });

    return (
        <React.Fragment>
            <div className={"dropDownDouble"}>
                <h3 className={"naslovMal"}>{translations?.products?.firmwareName}</h3>
                <div className={"cellDual"}>
                    <Select
                        maxMenuHeight={120}
                        onChange={(newValue, action) => { setCurrentFirmware(newValue) }}
                        options={firmwares}
                        value={currentFirmware}
                    />
                </div>
            </div>
            <div className={"dropDownDouble"}>
                <h3 className={"naslovMal"}>{translations?.products?.firmwareVersion}</h3>
                <div className={"cellDual"} style={{position: "relative", top : "-10px", left : "10px"}}>
                    <TextField 
                        label={translations?.products?.firmwareVersion}
                        value={currentFirmware.version}
                        disabled={true}
                    />
                </div>
            </div>
            <div style={{textAlign: "center"}}>
            <SaveButton 
                klasaButton={"buttonSave"}
                name={translations?.core?.update}
                handleButtonClick={() => props.handleEnter({tip : props.tip, newValues : currentFirmware})}
            />
            </div>
        </React.Fragment>
    )
  }
