import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useQuery} from "react-query";
import MaterialTable from "material-table";
import ErrorPrivilegesPath from "../auth/error-privileges";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RefreshIcon from '@material-ui/icons/Refresh';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import 'date-fns';
import { DialogContext, TranslationContext} from './../../App';
import {formatDatumVreme} from './../formatDates'
import {useHistory} from 'react-router-dom';
import {GraphDM} from './../C-inventar/graphDM.js'
import FlipToBackOutlinedIcon from '@material-ui/icons/FlipToBackOutlined';
import LibraryAddCheckOutlinedIcon from '@material-ui/icons/LibraryAddCheckOutlined';

const State = () => {
  const [redirect, setRedirect] = useState({state : false, status : 401})
  const {setSuccessZero, apiPath, selectedModule, setSelectedModule, refreshState} = useContext(DialogContext)
  const history = useHistory();
  const {translations} = useContext(TranslationContext)
  const [deviceState, setDeviceState] = useState([]);
  const [firstRender, setFirstRender] = useState(false)
  const [currentDevice, setCurrentDevice] = useState({})
  const [notShowGraph, setNotShowGraph] = useState(true);

  const response = useQuery("device-state", async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiPath}deviceStateMultipleDM.php?productID=4700`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if(response.data.success){
        setFirstRender(true);
        setDeviceState(response.data.data);

        if(typeof(currentDevice.serialID) === "undefined")
          setCurrentDevice(response.data.data[0])
        else{
          response.data.data.forEach((device)=>{
              if(device.serialID === currentDevice.serialID)
                setCurrentDevice(device)
          })
        }
      }
      else {
        // TO DO
        if(response.data.status === 401 || response.data.status === 403) // unauthorized
          setRedirect({state : true, status : response.data.status})
        else
          setSuccessZero({state : true, name : translations?.devices?.errorFetchingDeviceStateDM, text : translations?.backend[response.data.message]});
      }

      return response.data;
    } catch (err) {
    }
  });

  useEffect(() => {
    if(selectedModule !== "/state")
      setSelectedModule("/state")
  }, [])

  if(!firstRender && redirect.state === false)
    return <React.Fragment></React.Fragment>

  if(typeof(translations) === "undefined" || Object.keys(translations).length === 0)
    return <React.Fragment></React.Fragment>
  
  if(redirect.state){
    // ako ima token i sostojbata e ili neodredena ili tocna, ostavi go neka probat pak
    if((refreshState === '' || refreshState === 'true') && localStorage.getItem('token') !== null && redirect.status !== 403)
      history.go(0);
    else
      return <ErrorPrivilegesPath  status={redirect.status} />
  }

  return (
    <React.Fragment>
      <div className={"tableFS"}>
        <MaterialTable
          title={translations?.data?.tableState}
          options={{
            padding: "dense",
            actionsCellStyle : {paddingLeft : "10px"},
            headerStyle : {padding : "3px"},
          }}
          localization={{
            pagination : {
              firstTooltip : translations?.core?.firstPage,
              lastTooltip : translations?.core?.lastPage,
              nextTooltip : translations?.core?.nextPage,
              previousTooltip : translations?.core?.previousPage,
              labelRowsSelect: translations?.core?.rows,
              labelDisplayedRows: `{from}-{to} ${translations?.core?.rowsOf} {count}`
            },
            body : {
              emptyDataSourceMessage : translations?.core?.emptyTable
            },
            header: { actions: "" },
            toolbar: {
              searchPlaceholder: translations?.core?.search,
              searchTooltip : translations?.core?.search
            },
          }}
          columns={[
            { title: translations?.companies?.companyName, field: "companyName", 
              cellStyle : {padding : "0px 5px", paddingLeft : "30px"}, headerStyle : {paddingLeft : "30px"}},
            { title: translations?.products?.productType, field: "productName"},
            { title: translations?.devices?.serial, field: "serialNumber"},
            { title: translations?.locations?.place, field: "mestoName"},
            { title: "Visible", field: "isvisible", hidden : true},
            { title: translations?.data?.batteryVoltage, field: "battery", cellStyle : {paddingLeft : "15px"}, headerStyle : {paddingLeft : "15px"}},
            { title: translations?.data?.avgConnTime, field: "avg_connTime"},
            { title: translations?.data?.wifiHealth, field: "wifi"},
            { title: translations?.data?.minT, field: "min_T"},
            { title: translations?.data?.maxT, field: "max_T"},
            { title: translations?.data?.avgT, field: "avg_T"},
            { title: translations?.data?.latestReading, field: "lastUpdate", cellStyle : {paddingRight : "10px"}},
            { title: "id", field: "serialID", hidden : true},
          ]}
          data={deviceState.map((row) => {
            let min_T = Number(row.min_T) === 0 ? "–" : (parseFloat(row.min_T) / 100).toFixed(2)
            let max_T = Number(row.max_T) === 0 ? "–" : (parseFloat(row.max_T) / 100).toFixed(2)
            let avg_T = Number(row.avg_T) === 0 ? "–" : (parseFloat(row.avg_T) / 100).toFixed(2)
            let wifi =  Number(row.wifi) === 0 ? "–" : (parseFloat(row.wifi  * 100)).toFixed(1) + " %"
            let battery = Number(row.battery) === 0 ? "–" : (5 * parseFloat(row.battery) /  1024).toFixed(2)
            let avg_connTime = Number(row.avg_connTime) === 0 ? "–" : row.avg_connTime;

            return {...row, min_T, max_T, avg_T, wifi, avg_connTime, lastUpdate : formatDatumVreme(row.lastUpdate, translations?.data?.day), battery}
          })}
          actions={[
            (rowData) => ({
              icon : parseInt(rowData.OTA_updating) === 1 ? FlipToBackOutlinedIcon : LibraryAddCheckOutlinedIcon,
              tooltip : parseInt(rowData.OTA_updating) === 1 ? translations?.devices?.OTAunderway : translations?.devices?.OTAclear,
            }),
            (rowData) => ({
              icon: rowData.isvisible === "1" ? VisibilityIcon : VisibilityOffOutlinedIcon,
              tooltip : rowData.isvisible === "1" ? translations?.devices?.isVisible : translations?.devices?.isInvisible,
            }),
            {
              icon: RefreshIcon,
              tooltip: translations?.core?.refresh,
              isFreeAction: true,
              onClick: (event) => response.refetch()
            },
            () => ({
              icon: ShowChartIcon,
              tooltip: translations?.data?.showChart,
              onClick: (event, rowData) => {
                if(rowData.serialID === currentDevice.serialID)
                  setNotShowGraph(!notShowGraph)
                else
                  setNotShowGraph(false)

                deviceState.forEach((device) => {
                  if(device.serialID === rowData.serialID)
                    setCurrentDevice((device));
                })
              },
            })
          ]}
        />
        {!notShowGraph && (
          <GraphDM  serialID={currentDevice.serialID} setNotShowGraph={setNotShowGraph} />
        )}
      </div>
    </React.Fragment>
  );
};

export default State;
