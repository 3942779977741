import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { makeStyles} from '@material-ui/core';
import { DialogContext, TranslationContext} from './../../App';
import MaterialTable from 'material-table';
import { formatDatumVreme } from '../formatDates';
import './../styles/globalClasses.css'
import './localStyles.css'
import ErrorPrivilegesPath from '../auth/error-privileges';
import {AnalizaPieChart, AnalizaPieChartSmall} from './analiza-piechart';
import { exportToExcel } from './../export-xls';
import {useHistory} from 'react-router-dom';

const VALID_DATA = 0.8;
const PIECHART_DOWN_PIKSELI = 1470;
const MAL_PIECHART_PIKSELI = 675;

const useStyles = makeStyles((theme) => ({
  pieChartDiv : {
    width : "650px",
    height : "300px",
    float : "left",
    marginTop : "30px",
    flexShrink : "0",
    position : "relative",
    top : "0px",
    [theme.breakpoints.down(MAL_PIECHART_PIKSELI)]: {
      width : "100%",
      height : "240px"
    },
  },
  noDataDiv : {
    width : "650px",
    height : "200px",
    float : "left",
    marginTop : "30px",
    flexShrink : "0",
    position : "relative",
    top : "0px",
    boxSizing : "border-box",
    [theme.breakpoints.down(MAL_PIECHART_PIKSELI)]: {
      width : "100%",
      height : "100px"
    },
  },
  compartment : {
    width : "400px",
    textAlign : "center",
    [theme.breakpoints.down(PIECHART_DOWN_PIKSELI)]: {
      display : "inline-block",
      width : "45%",
      marginLeft : "2.5%",
      marginRight : "2.5%",
      marginTop : "20px",
      boxSizing : "border-box"
    },
    [theme.breakpoints.down(MAL_PIECHART_PIKSELI)]: {
      display : "block",
      width : "80%",
      margin : "auto",
      marginTop : "30px"
    },
  },
  glavenNaslov : {
    [theme.breakpoints.down(PIECHART_DOWN_PIKSELI)]: {
      width : "400px",
    },
    [theme.breakpoints.down(450)]: {
      width : "270px",
    },
  },
  analysisDataPercentage : {
    fontWeight : "bold",
    paddingBottom : "50px",
    textAlign : "center",
    marginTop : "0",
    position : "relative",
    top : "85px",
    [theme.breakpoints.down(MAL_PIECHART_PIKSELI)]: {
      top : "35px",
    },
  },
}));

// zemeno od reports
function getCO2Header(ppms = false) {
  return <React.Fragment>
          Max CO<sub><small>2</small></sub> {ppms && <small>[ppm]</small>}
      </React.Fragment>;
}

export const TabelaAnaliza = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [redirect, setRedirect] = useState({state : false, status : 401})
    const [analysisData, setAnalysisData] = useState([]);
    const [analysisSingle, setAnalysisSingle] = useState({})
    const [allowRender, setAllowRender] = useState(false)
    const [loading, setLoading] = useState(false)
    const [pieChartData, setPieChartData] = useState([
      {key : "pod",    value : 1000},
      {key : "medju",  value : 0},
      {key : "nad",    value : 0},
    ])

    const {translations} = useContext(TranslationContext)
    const {setSuccessZero, apiPath, refreshState} = useContext(DialogContext)
    const [width, setWidth]   = useState(window.innerWidth);

    useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      window.addEventListener("orientationchange", updateOrientation)
      return () => {
          window.removeEventListener("resize", updateDimensions)
          window.removeEventListener("orientationchange", updateOrientation)
      };
  }, []);

  const updateDimensions = () => {setWidth(window.innerWidth)};
  const updateOrientation = () => {
      setTimeout(() => {setWidth(window.innerWidth)}, 10) 
      setTimeout(() => {setWidth(window.innerWidth)}, 50) 
      setTimeout(() => {setWidth(window.innerWidth)}, 100) 
  }

    // povikaj samo koga ima promena na refetch
    useEffect(() => {
      let timer1 = setTimeout(() => {
        if(loading || props.analysis.refetch === true){
          if(props.analysis.multiple === true)
              responseMultiple.refetch(); 
          else if (props.analysis.single === true)
              responseSingle.refetch();  
        }
      }, 10);

      return () => {
        clearTimeout(timer1);
      };        
    }, [props.analysis.refetch]);
    

    // povleci analiza za eden ured so negovi parametri dadeni vo props
    const responseSingle = useQuery("analysis-single", async () => {
        try {
          setLoading(true);            
          if(loading || props.analysis.single === false)
            return true

          props.setAnalysis({...props.analysis, refetch : false})
          const token = localStorage.getItem("token");
          const paramsSerialized = new URLSearchParams(props).toString()

          const response = await axios.get(`${apiPath}getAnalysisSingle.php?${paramsSerialized}`, { headers: { Authorization: `Bearer ${token}` } });
          
          props.setButtonDisabled(false) // mozi da se klikaat pak kopcinjata

          if(response.data.success){
            setAllowRender(true)
            setAnalysisSingle(response.data.data)

            let podPrvPrag = parseInt(response.data.data.podPrvPrag * 1000);
            let nadVtorPrag = parseInt(response.data.data.nadVtorPrag * 1000);
            let medju = 1000 - podPrvPrag - nadVtorPrag; 

            setPieChartData([
              {key : "pod",    value : podPrvPrag},
              {key : "medju",  value : medju},
              {key : "nad",    value : nadVtorPrag},
            ])
          }
          else {
            // TO DO
            setAnalysisSingle({})
            setAllowRender(false)
            if(response.data.status === 401 || response.data.status === 403) // unauthorized
              setRedirect({state : true, status : response.data.status})
            else{
              if(localStorage.getItem("defaultsA") !== null)
              setSuccessZero({state : true, name : translations?.data?.errorFetchingSingleAnalysis, text : translations?.backend[response.data.message]});
            }
          }

          setLoading(false)
          
          return response.data;
        } catch (err) {
          setLoading(false)
        }
      });

      // povleci podatoci za analiza za povekjke uredi, paramtri dadeni kako props
      // ova e najsporoto API mislam, dali ce treba da se pravit tuka pagination ?!
      const responseMultiple = useQuery("analysis-multiple", async () => {
        try {
          if(props.analysis.multiple === false)
            return true

          props.setAnalysis({...props.analysis, refetch : false})
          const token = localStorage.getItem("token");
          const paramsSerialized = new URLSearchParams(props).toString()

          const response = await axios.get(`${apiPath}getAnalysisMultiple.php?${paramsSerialized}`, { headers: { Authorization: `Bearer ${token}` } });
          
          props.setButtonDisabled(false) // mozi da se klikaat pak kopcinjata

          if(response.data.success){
            setAnalysisData(response.data.data)
            setAllowRender(true)
          }
          else {
            // TO DO
            setAnalysisData([])
            setAllowRender(false)
            if(response.data.status === 401 || response.data.status === 403) // unauthorized
              setRedirect({state : true, status : response.data.status})
            else{
              if(localStorage.getItem("defaultsA") !== null)
                setSuccessZero({state : true, name : translations?.data?.errorFetchingMultipleAnalysis, text : translations?.backend[response.data.message]});
            }
          }

          return response.data;
        } catch (err) {
        }
      });

    const handleExportAction = () => {
      const renameKey = ( obj, oldKey, newKey ) => {
        if(oldKey === newKey){
          let temp = obj[oldKey]
          delete obj[oldKey];
          obj[newKey] = temp;
        }
        else{
          obj[newKey] = obj[oldKey];
          delete obj[oldKey];
        }
      }

      let maxCharacters = [
        translations?.devices?.settings.length,
        translations?.devices?.value.length,
        translations?.companies?.company.length,
        translations?.locations?.building.length,
        translations?.locations?.place.length,
        translations?.devices?.device.length,
        translations?.data?.underLimit1.length,
        translations?.data?.betweenLimits.length,
        translations?.data?.overLimit2.length,
        translations?.data?.dataPercentage.length,
      ]

      let analysisDataExport = [];
      analysisData.forEach((rowData, index) => {
        let {serialID, valid, ...rowDataFiltered} = rowData; // copy everything except serialID and valid
        if (rowDataFiltered.companyName.length > maxCharacters[2]) maxCharacters[2] = rowDataFiltered.companyName.length;
        if (rowDataFiltered.zgradaName.length > maxCharacters[3]) maxCharacters[3] = rowDataFiltered.zgradaName.length;
        if (rowDataFiltered.mestoName.length > maxCharacters[4]) maxCharacters[4] = rowDataFiltered.mestoName.length;
        if (rowDataFiltered.deviceName.length > maxCharacters[5]) maxCharacters[5] = rowDataFiltered.deviceName.length;
        
        let validData = rowData.dataPercentage > VALID_DATA;
        rowDataFiltered.podPrvPrag = validData ? parseFloat(rowData.podPrvPrag  * 100).toFixed(1) + " %" : "–";
        rowDataFiltered.nadVtorPrag = validData ? parseFloat(rowData.nadVtorPrag  * 100).toFixed(1) + " %"    : "–";
        rowDataFiltered.medju = validData ? parseFloat(rowData.medju  * 100).toFixed(1) + " %" : "–";
        rowDataFiltered.max = validData ? rowData.max : "–";
        rowDataFiltered.logMax = formatDatumVreme(rowData.logMax);
        rowDataFiltered.dataPercentage = rowData.dataPercentage >= VALID_DATA ? "OK" : "NOK";

        analysisDataExport.push(rowDataFiltered)
      })

      let analysisDate = (new Date()).toLocaleString("de-DE").slice(0, -3).replace(',', ''); // vreme bez sekundi i zapirka
      for(let i = 0; i < 6; i++){
        let postavka = "";
        let vrednost = "";

        switch(i){
          case 0: postavka = translations?.data?.analysisDate;  vrednost = analysisDate;    break;
          case 1: postavka = translations?.data?.interval;      
                Number(props.interval) === 1 ? vrednost = translations?.data?.day : vrednost = props.interval + " " + translations?.data?.days;  break;
          case 2: postavka = translations?.data?.activeHours;   vrednost = props.startTime + " - " + props.endTime; break;
          case 3: postavka = translations?.data?.weekends;      
                props.weekends ? vrednost = translations?.data?.yes : vrednost = translations?.data?.no;  break;
          case 4: postavka = translations?.data?.limit1;        vrednost = props.prvPrag + " ppm";   break;
          case 5: postavka = translations?.data?.limit2;        vrednost = props.vtorPrag + " ppm";  break;
          default:
            break;
        }

        // Ima uredi za analiza, zapisi vo istiot red
        if(i < analysisDataExport.length){
          analysisDataExport[i]["postavki"] = postavka;
          analysisDataExport[i]["vrednost"] = vrednost;
        }
        else{
          // nema dovolno uredi vo samata analiza kolku sto imat postavki sto trebit da se zapiset vo ekselot
            let dummy = {companyName : "", zgradaName : "", mestoName : "", deviceName : "", podPrvPrag : "", medju : "", nadVtorPrag : "",
                      max : "", logMax : "", dataPercentage : ""}
  
            dummy["postavki"] = postavka;
            dummy["vrednost"] = vrednost;
            analysisDataExport.push(dummy);
        }
      }

      analysisDataExport.forEach( obj => renameKey( obj, 'postavki', translations?.devices?.settings ));
      analysisDataExport.forEach( obj => renameKey( obj, 'vrednost', translations?.devices?.value ));

      analysisDataExport.forEach( obj => renameKey( obj, 'companyName', translations?.companies?.company ));
      analysisDataExport.forEach( obj => renameKey( obj, 'zgradaName', translations?.locations?.building ));
      analysisDataExport.forEach( obj => renameKey( obj, 'mestoName', translations?.locations?.place ));
      analysisDataExport.forEach( obj => renameKey( obj, 'deviceName', translations?.devices?.device ));
      analysisDataExport.forEach( obj => renameKey( obj, 'podPrvPrag', translations?.data?.underLimit1 ));
      analysisDataExport.forEach( obj => renameKey( obj, 'medju', translations?.data?.betweenLimits ));
      analysisDataExport.forEach( obj => renameKey( obj, 'nadVtorPrag', translations?.data?.overLimit2 ));
      analysisDataExport.forEach( obj => renameKey( obj, 'max', 'Max CO2 [ppm]' ));
      analysisDataExport.forEach( obj => renameKey( obj, 'logMax', translations?.data?.logMax + " Max CO2"));
      analysisDataExport.forEach( obj => renameKey( obj, 'dataPercentage', translations?.data?.dataPercentage ));

      maxCharacters = maxCharacters.map(c => c + 3)

      let endDate = (new Date()).toLocaleDateString("de-DE")
      let startDate = (new Date(new Date().setDate(new Date().getDate() - props.interval + 1 ))).toLocaleDateString("de-DE")
      let fileName = translations?.data?.exportDocumentName + " " + startDate + (props.interval !== 1 ? "-" + endDate : "")

      exportToExcel(analysisDataExport, fileName, maxCharacters)
    } 

    if(typeof(translations) === "undefined" || Object.keys(translations).length === 0)
      return <React.Fragment></React.Fragment>

    if(redirect.state){
      // ako ima token i sostojbata e ili neodredena ili tocna, ostavi go neka probat pak
      if((refreshState === '' || refreshState === 'true') && localStorage.getItem('token') !== null && redirect.status !== 403)
        history.go(0);
      else
        return <ErrorPrivilegesPath  status={redirect.status} />
    }
      
    // ako se vratit success ama nema dostapni podatoci (primer sme mu stale na uredot isvisible = 0), togas ne davaj analiza
    if(allowRender === false)
        return <React.Fragment></React.Fragment>

    return (
      <>
        {/* Analiza za eden ured */}
        {props.analysis.single && (
          <React.Fragment>
            <h2 className={`addSectionGlavenNaslov ${classes.glavenNaslov}`} style={{marginTop : "0px", color : "black", borderBottom : "3px solid var(--blue)"}}>{translations?.data?.analysisFor} {props.deviceName}</h2>
            {width < PIECHART_DOWN_PIKSELI && (
              <div className="greatDivFloating">
                <div className={classes.compartment}>
                    <h3 className="naslovMal" style={{width : "200px"}}>{translations?.locations?.location}</h3>
                    <table>
                      <thead>
                        <tr><td>{translations?.locations?.building}</td><td>{analysisSingle.zgradaName}</td></tr>
                        <tr><td>{translations?.locations?.place}</td><td>{analysisSingle.mestoName}</td></tr>
                        <tr><td>{translations?.devices?.device}</td><td>{analysisSingle.deviceName}</td></tr>
                      </thead>
                    </table>
                </div>
                <div className={classes.compartment} style={{position : "relative", top : "-3.84px"}}>
                  <h3 className="naslovMal"  style={{width : "200px"}}>{getCO2Header()}</h3>
                  <table>
                    <thead>
                        <tr>
                          <td>{getCO2Header()}</td>
                          <td>{Number(analysisSingle.max) === 0 ? "–" : <React.Fragment>{analysisSingle.max}<small> ppm</small></React.Fragment>}</td>
                        </tr>
                        <tr><td>{translations?.data?.logMax} {getCO2Header()}</td><td>{formatDatumVreme(analysisSingle.logMax, translations?.data?.day)}</td></tr>
                    </thead>
                  </table>
                </div>
              </div>
            )}
            <div className="greatDiv">
              {width >= PIECHART_DOWN_PIKSELI && (
                <div className={classes.compartment}>
                  <h3 className="naslovMal" style={{width : "200px"}}>{translations?.locations?.location}</h3>
                  <table>
                    <thead>
                      <tr><td>{translations?.locations?.building}</td><td>{analysisSingle.zgradaName}</td></tr>
                      <tr><td>{translations?.locations?.place}</td><td>{analysisSingle.mestoName}</td></tr>
                      <tr><td>{translations?.devices?.device}</td><td>{analysisSingle.deviceName}</td></tr>
                    </thead>
                  </table>
                </div>
              )}
              {analysisSingle.valid === 1 && (
                <div className={classes.pieChartDiv}>
                  {width >= MAL_PIECHART_PIKSELI ? <AnalizaPieChart pieChartData={pieChartData} /> : <AnalizaPieChartSmall pieChartData={pieChartData} />}
                </div>
              )}
              {/* Дозволи приказ само ако анализата не е валидна */}
              {analysisSingle.valid !== 1 && (
                <div className={classes.noDataDiv}>
                <p className={classes.analysisDataPercentage}>
                    {Number(analysisSingle.dataPercentage) > 0.8 ?(
                      <React.Fragment>
                          {translations?.data?.analysisMadeWith[0]} {parseFloat(analysisSingle.dataPercentage  * 100).toFixed(1) + "% " } 
                          {translations?.data?.analysisMadeWith[1]}
                      </React.Fragment>
                    ) :(
                      <React.Fragment>
                          {translations?.data?.analysisNotMadeWith} {parseFloat(analysisSingle.dataPercentage  * 100).toFixed(1) + "% " } 
                      </React.Fragment>
                    )}                  
                  </p>
                </div>
              )}
              {width >= PIECHART_DOWN_PIKSELI && (
                <div className={classes.compartment} style={{position : "relative", top : "-13.5px"}}>
                  <h3 className="naslovMal"  style={{width : "200px"}}>{getCO2Header()}</h3>
                  <table>
                    <thead>
                        <tr>
                          <td>{getCO2Header()}</td>
                          <td>{Number(analysisSingle.max) === 0 ? "–" : <React.Fragment>{analysisSingle.max}<small> ppm</small></React.Fragment>}</td>
                        </tr>
                        <tr><td>{translations?.data?.logMax} {getCO2Header()}</td><td>{formatDatumVreme(analysisSingle.logMax, translations?.data?.day)}</td></tr>
                    </thead>
                  </table>
                </div>
              )}
            </div>

          </React.Fragment>
        )}
        {/* Analiza za povekje uredi */}
        {props.analysis.multiple && (
          <React.Fragment>
            <MaterialTable
              title={translations?.data?.tableAnalysis}
              options={{
                padding: "dense",
                headerStyle : {padding : "3px"},
                actionsCellStyle : {paddingLeft : "8px"},
              }}
              localization={{
                header: { actions: translations?.core?.actions },
                pagination : {
                  firstTooltip : translations?.core?.firstPage,
                  lastTooltip : translations?.core?.lastPage,
                  nextTooltip : translations?.core?.nextPage,
                  previousTooltip : translations?.core?.previousPage,
                  labelRowsSelect: translations?.core?.rows,
                  labelDisplayedRows: `{from}-{to} ${translations?.core?.rowsOf} {count}`
                },
                body : {
                  emptyDataSourceMessage : translations?.core?.emptyTable
                },
                toolbar: {
                  searchPlaceholder: translations?.core?.search,
                  searchTooltip : translations?.core?.search,
                },
              }}
              columns={[
                { title: translations?.locations?.building, field: "zgradaName", 
                      cellStyle : {padding : "0px 5px", paddingLeft : "10px"}, headerStyle : {paddingLeft : "10px"}},
                { title: translations?.locations?.place, field: "mestoName"},
                { title: translations?.devices?.device, field: "deviceName",
                      cellStyle : {padding : "0px 5px", paddingRight : "15px"}},
                { title: translations?.data?.underLimit1, field: "podPrvPrag"},
                { title: translations?.data?.betweenLimits, field: "medju"},
                { title: translations?.data?.overLimit2, field: "nadVtorPrag"},
                { title: getCO2Header(true), field : "max"},            
                { title: <React.Fragment>{translations?.data?.logMax} {getCO2Header()}</React.Fragment>, field : "logMax"},            
                { title: translations?.data?.dataPercentage, field: "dataPercentage"},
              ]}
              data={analysisData.map((dat) => {
                let valid = parseFloat(dat.dataPercentage) > VALID_DATA
                let podPrvPrag = valid ? parseFloat(dat.podPrvPrag  * 100).toFixed(1) + " %" : "–"
                let nadVtorPrag = valid ? parseFloat(dat.nadVtorPrag  * 100).toFixed(1) + " %" : "–"
                let medju = valid ? parseFloat(dat.medju  * 100).toFixed(1) + " %" : "–"
                let max = valid ? dat.max : "–";
                let dataPercentage = valid ? 
                  <div style={{color : "green", fontWeight : "bold"}}>OK</div> :
                  <div style={{color : "red", fontWeight : "bold"}}>NOK</div>
                
                return {...dat, logMax : formatDatumVreme(dat.logMax, translations?.data?.day), podPrvPrag, nadVtorPrag, medju, dataPercentage, max}
              })}
              actions={[
                {
                  icon: 'save_alt',
                  tooltip: translations?.core?.exportTooltip,
                  isFreeAction: true,
                  onClick: () => {handleExportAction()}
                }
              ]}
            />
          </React.Fragment>
        )}
      </>
    )
}

