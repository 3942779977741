import React, { useEffect, useState, createContext } from 'react';
import axios from 'axios';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider, useTheme } from '@material-ui/core';
import { PrivateRoute } from './components/private-route';
import {ErrorDialog, NotificationDialog} from './components/dialog-popups';

import { Login } from './components/auth/login.js';
import { Register } from './components/auth/register.js';

import { Users }    from './components/modules/users';
import { Devices }  from './components/modules/devices';
import { Reports }  from './components/modules/reports';
import { Analiza }  from './components/modules/analiza';

import Inventar   from './components/modules/inventar'
import Companies  from './components/modules/companies';
import Catalog    from './components/modules/catalog';
import Servers    from './components/modules/servers';
import Test       from './components/modules/test';
import State      from './components/modules/state';

export const DialogContext = createContext({});
export const TranslationContext = createContext({});

function App() {
  const theme = useTheme();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  
  const [translations, setTranslations] = useState({});
  const [langCode, setLangCode] = useState("mk");
  const [userInfo, setUserInfo] = useState({});
  const [successfullLogin, setSuccessfullLogin] = useState(false);
  const [refreshTokenJob, setRefreshTokenJob] = useState(false)
  const [refreshState, setRefreshState] = useState('')
  const [successZero, setSuccessZero] = useState({state : false, name : "", text : ""})
  const [notifyPopUp, setNotifyPopUp] = useState({state : false, name : "", text : ""})
  const [selectedModule, setSelectedModule] = useState("/reports")
  const apiPath = "";

  useEffect(() => {
    async function fetchTranslations() {
      const savedLangCode = localStorage.getItem("langCode")
      let langCodeToSend = "mk"
      savedLangCode === null ? langCodeToSend = langCode : langCodeToSend = savedLangCode;

      const token = localStorage.getItem("token");
      const response = await axios.get(`${apiPath}fetchTranslations.php?langCode=${langCodeToSend}`, { headers: { Authorization: `Bearer ${token}` } });
      setTranslations(response.data.translations);
    }
    
    fetchTranslations();
  }, [langCode, successfullLogin]);

  // resava problem ako korisnikot ne navigira preku Router (in-app) tuku so direkten link
  useEffect(() => {
    async function fetchCurrentUser() {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${apiPath}currentUser.php`, { headers: { Authorization: `Bearer ${token}` } });
      if(response.data.success){
        setUserInfo(response.data.user);
      }
      else
        setUserInfo({});
    }
    async function refreshToken() {
      const token = localStorage.getItem("token");
      const response = await axios.post(`${apiPath}refreshToken.php`, { headers: { Authorization: `Bearer ${token}` } });
      if(response.data.success){
        localStorage.setItem("token", response.data.token)
        setRefreshState('true')
      }
      else{
        // sto bilo drugo da se desit, vrati false
        setRefreshState('false')
      }
    }
    
    const savedLangCode = localStorage.getItem("langCode")
    savedLangCode === null ? setLangCode("mk") : setLangCode(savedLangCode);

    refreshToken();
    fetchCurrentUser();
  }, [])

  // refreshToken na sekoi 15 minuti
  useEffect(() => {
    let refreshTokenTimer = setTimeout(() => {
      setRefreshTokenJob(!refreshTokenJob)

      async function refreshToken() {
        const token = localStorage.getItem("token");
        const response = await axios.post(`${apiPath}refreshToken.php`, { headers: { Authorization: `Bearer ${token}` } });
        if(response.data.success)
          localStorage.setItem("token", response.data.token)
      }

      refreshToken();
    }, 900000);
        
    return () => {
      clearTimeout(refreshTokenTimer);
    };
  }, [refreshTokenJob]);

  return (
    <React.Fragment>
      <div className='App'>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <TranslationContext.Provider
              value={{
                langCode,
                setLangCode,
                translations,
                setTranslations,
              }}
            >
              {/* Imeto DialogContext ne e reprezentativno voopsto, sedi od istoriski pricini (setSuccessZero e Dialog Box) */}
              <DialogContext.Provider
                value={{
                  setSuccessZero,
                  setNotifyPopUp,
                  apiPath,
                  setUserInfo,
                  userInfo,
                  selectedModule,
                  setSelectedModule,
                  refreshState,
                  setSuccessfullLogin,
                }}
              >
                  <Router basename={process.env.PUBLIC_URL}>  

                    <PrivateRoute exact={true} path='/login' component={Login} />
                    <PrivateRoute exact={true} path='/register' component={Register} />

                    <PrivateRoute exact={true} path='/' component={Reports} />
                    <PrivateRoute exact={true} path='/users' component={Users} />
                    <PrivateRoute exact={true} path='/devices' component={Devices} />
                    <PrivateRoute exact={true} path='/reports' component={Reports} />
                    <PrivateRoute exact={true} path='/analiza' component={Analiza} />
                    
                    <PrivateRoute exact={true} path='/inventar' component={Inventar} />
                    <PrivateRoute exact={true} path='/companies' component={Companies} />
                    <PrivateRoute exact={true} path='/catalog' component={Catalog} />
                    <PrivateRoute exact={true} path='/servers' component={Servers} />
                    <PrivateRoute exact={true} path='/test' component={Test} />
                    <PrivateRoute exact={true} path='/state' component={State} />

                  </Router>
                  
                  {successZero.state && 
                    <ErrorDialog name={successZero.name} text={successZero.text} 
                                handleOnClick={() => setSuccessZero({state : false, name : "", text : ""})} />
                  } 
                  {notifyPopUp.state && 
                    <NotificationDialog name={notifyPopUp.name} text={notifyPopUp.text} 
                                handleOnClick={() => setNotifyPopUp({state : false, name : "", text : ""})} />
                  } 

              </DialogContext.Provider>
            </TranslationContext.Provider>
          </QueryClientProvider>
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
}

export default App;
